import React from 'react'

import car from '../../images/car.svg'
import bicycle from '../../images/bicycle.svg'
import bus from '../../images/bus.svg'
import gravestone from '../../images/gravestone.svg'
import injury from '../../images/injury.svg'
import motorbike from '../../images/motorbike.svg'
import pedestrian from '../../images/pedestrian.svg'
import truck from '../../images/truck.svg'

const Icons = props => {
  switch (props.icon) {
  case 'car':
    return <img
      src={ car }
      { ...props }
    />
  case 'biycycle':
    return <img
      src={ bicycle }
      { ...props }
    />
  case 'bus':
    return <img
      src={ bus }
      { ...props }
    />
  case 'injury':
    return <img
      src={ injury }
      { ...props }
    />
  case 'gravestone':
    return <img
      src={ gravestone }
      { ...props }
    />
  case 'motorbike':
    return <img
      src={ motorbike }
      { ...props }
    />
  case 'pedestrian':
    return <img
      src={ pedestrian }
      { ...props }
    />
  case 'truck':
    return <img
      src={ truck }
      { ...props }
    />
  default:
    return <></>
  }
}

export default Icons
