import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'
import Icons from '../components/icons'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHero from '../components/hero/page'
import Breadcrumbs from '../components/breadcrumbs'
import Contact from '../components/contact'
import HorizontalForm from '../components/forms/horizontal'

import styles from '../styles/page.module.scss'

const WhatWeDoPage = () => (
  <StaticQuery
    query={graphql`
      query WhatWeDoQuery {
        currentPage: allContentYaml(filter: { url: { eq:"/what-we-do" } }) {
          edges {
            node {
              title
              content
              description
              breadcrumbs {
                name
                url
              }
              heroLeadLine
              heroLine
              heroPoints
              infoCardsTitle
              infoCards {
                cardTitle
                cardImage
                cardDescription
              }
            }
          }
        }

        globalSettings: allContentYaml(filter: {
          type: { eq: "GlobalSettings" }
        }) {
          edges {
            node {
              callToActionTitle
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.currentPage.edges[0].node
      const settings = data.globalSettings.edges[0].node

      return (
        <Layout>
          <SEO
            title={ page.title }
            description={ page.description }
            canonical="https://motorinjuryhelpline.com.au/what-we-do"
          />

          <PageHero
            leadLine={ page.heroLeadLine }
            heroLine={ page.heroLine }
            heroPointsLead={
              `Motor Injury Helpline is here to help anyone who’s suffered an 
              injury in a motor accident.  Our panel specialist Lawyers we 
              will offer you a No Win No Fee guarantee for the following 
              areas:`
            }
            heroPoints={ page.heroPoints }
          />

          <div className={ styles.content }>
            <div className={ styles.content__container }>
              <Breadcrumbs breadcrumbArray={ page.breadcrumbs } />
              <Markdown
                children={ page.content }
                options={{
                  overrides: {
                    Link: {
                      component: Link,
                    },
                    Icons: {
                      component: Icons,
                    }
                  },
                }}
              />
            </div>
          </div>

          <div className={ styles.horizontalForm__background_padded }>
            <HorizontalForm
              formTitle={ settings.callToActionTitle }
            />
          </div>

          <Contact />

        </Layout>
      )
    }
    }
  />
)

export default WhatWeDoPage
