import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Breadcrumbs = ({ breadcrumbArray }) => {
  let elems = []

  breadcrumbArray.forEach((crumb, index) => {
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname &&
    window.location.pathname === crumb.url
      ? elems.push(
        <span
          itemProp="itemListElement"
          itemType="https://schema.org/ListItem"
          itemScope
          key={ index }
        >
          <Link
            to={ crumb.url }
            key={ index }
            itemProp="item"
            itemType="https://schema.org/Thing"
          >
            <strong itemProp="name">
              { crumb.name }
            </strong>
            <meta
              itemProp="position"
              content={ index + 1 }
            />
          </Link>
        </span>
      )
      : elems.push(
        <span
          itemProp="itemListElement"
          itemType="https://schema.org/ListItem"
          itemScope
          key={ index }
        >
          <Link
            to={ crumb.url }
            key={ index }
            itemProp="item"
            itemType="https://schema.org/Thing"
          >
            <span itemProp="name">
              { crumb.name }
            </span>
            <meta
              itemProp="position"
              content={ index + 1 }
            />
          </Link>
        </span>
      )

    if (index + 1 < breadcrumbArray.length) {
      elems.push(' › ')
    }
  })

  return <p
    itemScope
    itemType="https://schema.org/BreadcrumbList"
  >
    { elems }
  </p>
}

Breadcrumbs.propTypes = {
  breadcrumbArray: PropTypes.array.isRequired,
}

export default Breadcrumbs
